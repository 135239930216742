<template>
  <div class="container flex flex-col items-center justify-center h-full">
    <img src="@/_assets/images/404.jpg" class=" w-1/2 h-full object-contain" />
    <h1 class="text-6xl font-bold text-gray-600">
      Spiacenti. qui non c'è nulla
    </h1>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
